$(function() {
//     $('.feature').css('opacity', 0.3);
//     $('.feature .btn').prop('disabled', true);
//     function onAcceptTerms() {
//         $('.feature').css('opacity', 1);
//         $('.feature .btn').prop('disabled', false);
//     }
//     function onDisacceptTerms() {
//         $('.feature').css('opacity', 0.3);
//         $('.feature .btn').prop('disabled', true);
//     }
//    $('#acceptButton').click(function() {
//        console.log('yes');
//        $('#term_input').prop('checked', true);
//        onAcceptTerms();
//    })
//    $('#term_input').on('change', function() {
//        if($(this).prop('checked')) {
//            onAcceptTerms();
//        } else {
//             onDisacceptTerms();
//        }
//    })

   $('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top-101
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          }
        });
      }
    }
  });


// if(window.location.href.indexOf('?')>=0){
//   $('#feature-block-1').addClass('disabled-block');
//   jQuery('#term_input').click();
//   jQuery('#term_input').attr('disabled','disabled');
//   setTimeout(function(){$('.disabled-block button').attr('disabled','disabled');},200)
// }
// else{
//   jQuery('#term_input').click();
//   $('#term').click(function() {
//        $('#termModal').modal();
//    })
// }
// $('#term_input').change(function(){
//   setTimeout(function(){$('.disabled-block button').attr('disabled','disabled');},200)
//
// })

})
